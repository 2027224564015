<template>
  <div id="app">
    <!-- Toasts -->
    <div aria-live="polite" aria-atomic="true" style="position: fixed; top: 20px; right:20px;">
      <div style="position: absolute; top: 0; right: 0; ">
        <toast v-for="toast in toasts" :key="toast.id" :title="toast.title" :message="toast.message" :colour="toast.colour"></toast>
      </div>
    </div>
    <comment-form></comment-form>
    <!--<div class="mb-5">
      <div class="form-group">
        <textarea class="form-control" rows="4" v-model="newComment.content"></textarea>
      </div>
      <button class="btn btn-primary" @click="createComment" type="button" v-bind:disabled="newComment.submitting">Submit comment</button>
    </div>-->
    <comment v-for="comment in comments" :key="comment.id" :comment="comment"></comment>
    <!--<div v-for="comment in comments">
      <p><strong>{{comment.id}}. {{comment.user.name}} commented {{comment.created_at | formatDate}}</strong></p>
      {{comment.content}}
      <p class="small text-muted">{{comment.replies.length}} replies</p>
      <hr>
    </div>-->
    <button class="btn btn-info mb-5" @click="loadMore">Load more</button>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Comment from 'comment'
import CommentForm from 'comment_form'
import Toast from 'toast'
export default {
  components: {Comment, CommentForm, Toast},
  data() {
    return {
      toasts: [],
      currentPage: 0, // will be incremented on initial fetch
      comments: [],
      newComment: {content:"", comment_id: null, submitting: false}
    }
  },
  mounted() {
    this.$cable.subscribe({ channel: 'CommentsChannel', id: 1 })
    this.loadMore()
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).fromNow()//format('MM/DD/YYYY HH:mm')
    }
  },
  computed: {
    rootCommentIds() {
      return this.comments.map(comment => comment.id)
    }
  },
  methods: {
    createComment() {
      this.newComment.submitting = true
      axios
        .post('/posts/1/comments.json',{comment: this.newComment})
        .then(response => {
          this.resetNewComment()
        })
        /*.then(response => {
          this.comments.unshift(response.data)
          this.newComment = {}
        })*/
    },
    resetNewComment() {
      this.newComment = {content:"", submitting: false}
    },
    loadMore() {
      this.currentPage += 1;
      axios
        .get(`/posts/1/comments.json?page=${this.currentPage}`)
        .then( (response) => {
          this.comments.push(...response.data)
        }
      )
    }
  },
  channels: {
    CommentsChannel: {
      connected() {
        //console.log('Connected!')
      },
      received(data) {
        // if comment is a reply
        if (data.comment_id) {
          // if the root exists
          if (this.rootCommentIds.includes(data.comment_id)) {
            // add the reply to the root's replies
            this.comments.find(comment => comment.id == data.comment_id).replies.push(data)
          } else {
            // the root doesn't exist so don't do anything
          }
          this.toasts.push({id: data.id, title: "New reply", message: `${data.user.name} replied to a comment.`, colour: 'success'})
        } else {
          // add new comment as root
          this.comments.unshift(data)
          this.toasts.push({id: data.id, title: "New comment", message: `${data.user.name} posted a new comment.`, colour: 'primary'})
        }
        // if comment is a reply, check if the root is present
        // if the root is present, add reply to root's replies

        // otherwise do nothing as there is no root to add the reply to
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
