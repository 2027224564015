<template>
	<div class="mb-5">
    <div class="form-group">
      <textarea class="form-control" :rows="comment_id ? 2 : 4" v-model="content"></textarea>
    </div>
    <button class="btn" :class="[comment_id ? 'btn-light btn-sm' : 'btn-primary']" @click="submitComment" type="button" v-bind:disabled="submitting">Submit {{comment_id ? "reply" : "comment"}}</button>
	</div>
</template>

<script>
import axios from 'axios'
export default {
	props: ['commentId'],
	data() {
		return {
			content: null,
			comment_id: this.commentId,
			submitting: false
		}
	},
	methods: {
		submitComment() {
      this.submitting = true
      axios
        .post('/posts/1/comments.json',{comment: this.$data})
        .then(response => {
          this.reset()
        })
    },
    reset() {
    	this.content = null
    	this.submitting = false
    }
	}
}
</script>

<style scoped land="scss">

</style>