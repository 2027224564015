<template>
	<div class="media mb-3">
		<i class="fas fa-user-circle fa-2x mr-3"></i>
		<div class="media-body">
			<h6 class="mt-0 small">{{comment.user.name}} commented {{comment.created_at | formatDate}}</h6>
			<div v-html="formattedContent"></div>
			<div v-if="comment.replies && comment.replies.length > 0">
				<p class="small">{{comment.replies.length}} replies:</p>
				<comment v-for="reply in comment.replies" :key="reply.id" :comment="reply"></comment>
			</div>
			<comment-form v-if="!comment.comment_id" :comment-id="comment.id"></comment-form>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import Comment from 'comment'
import CommentForm from 'comment_form'
export default {
	name: 'comment',
	props: ['comment'],
	components: {Comment,CommentForm},
	data() {
		return {
			/*id: this.comment.id,
			user_id: this.comment.user_id,
			content: this.comment.content,
			created_at: this.comment.created_at,
			user: this.comment.user,
			replies: this.comment.replies*/
		}
	},
	computed: {
		formattedContent() {
    	var str = this.comment.content.replace(/\r\n?/, "\n");
		  str = $.trim(str);
		  if (str.length > 0) {
		    str = str.replace(/\n\n+/g, '</p><p>');
		    str = str.replace(/\n/g, '<br />');
		    str = '<p>' + str + '</p>';
		  }
		  return str
    }
	},
	filters: {
    formatDate(value) {
      return moment(String(value)).fromNow()//format('MM/DD/YYYY HH:mm')
    }
  },
}
</script>

<style scoped land="scss">

</style>
