// Dome back to https://www.npmjs.com/package/actioncable-vue

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import ActionCableVue from 'actioncable-vue'
import Comments from '../comments.vue'

import axios from 'axios'
axios.defaults.headers.common['X-CSRF-TOKEN'] = $('[name="csrf-token"]').attr('content');

Vue.use(TurbolinksAdapter)

Vue.use(ActionCableVue, {
	debug: true,
	debugLevel: 'error',
	connectionUrl: '/cable' // ws://localhost:5000/api
})

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    el: '#comments',
    /*data: () => {
      return {
        message: "Can you say hello?"
      }
    },*/
    components: { Comments }
  })
})
