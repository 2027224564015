<template>
	<div class="toast" ref="toast" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="true" data-delay="2000">
    <div class="toast-header">
    	<i class="fas fa-circle mr-1" :class="`text-${colour}`"></i>
      <strong class="mr-auto">{{title}}</strong>
      <small class="text-muted">just now</small>
      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="toast-body">
      {{message}}
    </div>
  </div>
</template>

<script>
export default {
	props: ['title','message','colour'],
	mounted() {
		$(this.$refs.toast).toast('show')

		var context = new AudioContext()
		var o = context.createOscillator()
		var g = context.createGain()
		o.connect(g)
		o.type='sine'
		g.connect(context.destination)
		o.start(0)
		g.gain.exponentialRampToValueAtTime(0.00001,context.currentTime + 1.5)

	}
}
</script>

<style scoped lang="scss">
.toast {
	min-width:350px;
}
</style>